@import "abstracts/all"

html, body
  background: var(--bg-color)
  margin: 0 auto
  min-height: 100vh
  box-sizing: border-box

  font-family: $font-family-base
  +text-size-default

b
  font-weight: $font-weight-bold
