@import "../abstracts/all"

body
  display: flex
  min-height: 100vh
  flex-direction: column

.flex-footer
  flex: 1

.theme-light
  background: var(--section1-bg-color)
  color: var(--section1-text-color)

.theme-dark
  background: var(--section2-bg-color)
  color: var(--section2-text-color)

.box-border-bottom
  border-bottom: 4px solid var(--big-border-color)

footer
  background: var(--footer-bg-color)
  color: var(--footer-text-color)
  padding: $spacer * 1 $spacer * 3
