@use "sass:math"

@import "abstracts/all"

.mb-0
  margin-bottom: 0 !important

.mb-1
  margin-bottom: math.div($spacer, 2) !important

.mb-2
  margin-bottom: $spacer !important

.mb-3
  margin-bottom: $spacer * 1.5 !important

.mb-4
  margin-bottom: $spacer * 2 !important

.mb-5
  margin-bottom: $spacer * 2.5 !important

.mb-6
  margin-bottom: $spacer * 3 !important

.mb-10
  margin-bottom: $spacer * 5 !important

.mr-1
  margin-right: $spacer * 0.25 !important

.mr-2
  margin-right: $spacer * 0.5 !important

.mr-3
  margin-right: $spacer * 0.75 !important

.pb-1
  padding-bottom: math.div($spacer, 2) !important

.pb-2
  padding-bottom: $spacer !important

.pb-3
  padding-bottom: $spacer * 1.5 !important

.pb-4
  padding-bottom: $spacer * 2 !important

.pb-10
  padding-bottom: $spacer * 5 !important

.pt-3
  padding-top: $spacer * 3

.px-3
  padding-left: $spacer * 3
  padding-right: $spacer * 3
