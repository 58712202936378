$spacer: 1rem

$line-height-base: 2.5rem

$text-color: #333

$color-heading: #222
$color-heading-lighter: #333

\:root
  --bg-color: #c3e1e1

  --big-border-color: #153a41

  --section1-bg-color: var(--bg-color)
  --section1-text-color: #424242

  --section2-bg-color: #efeee8
  --section2-text-color: #153a41

  --footer-bg-color: #222222
  --footer-text-color: #aaa7a8

  --link-color: #c55e53
  --link-color-hover: #c55e53
