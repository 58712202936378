@use "sass:math"

@import "abstracts/all"

.center
  display: flex
  justify-content: center
  margin-left: auto !important
  margin-right: auto !important

.d-block
  display: block

.d-flex
  display: flex

.justify-space-between
  justify-content: space-between

.align-items-center
  align-items: center

.mw-600
  max-width: 600px

.mw-800
  max-width: 800px

.list
  margin: 0 0 $spacer 0
  padding: 0 0 0 $spacer
  .list-item
    list-style: circle
    margin: 0 0 math.div($spacer, 2) $spacer
