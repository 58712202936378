$font-family-base:     Inter, "Helvetica Neue", sans-serif
$font-family-headings: Inter, "Helvetica Neue", sans-serif

$font-size-base:       1rem
$line-height-base:     1.4rem

$font-weight-bold: 700

@mixin text-size-default
  font-size: $font-size-base
  line-height: $line-height-base
