@use "sass:math"

@import "abstracts/all"

// fontsource/inter npm
$font-display: fallback

@font-face
  font-family: "Inter"
  font-style: normal
  font-display: $font-display
  font-weight: 300
  src: url("../fonts/inter-latin-300-normal.woff2") format("woff2")

@font-face
  font-family: "Inter"
  font-style: normal
  font-display: $font-display
  font-weight: 400
  src: url("../fonts/inter-latin-400-normal.woff2") format("woff2")

@font-face
  font-family: "Inter"
  font-style: normal
  font-display: $font-display
  font-weight: 700
  src: url("../fonts/inter-latin-700-normal.woff2") format("woff2")

.letter-spacing-n1
  letter-spacing: -1px

.text-size-h1
  font-size: 2rem
  line-height: 3rem
  font-weight: bold

.text-size-h2
  font-size: 1.7rem
  line-height: 2.1rem
  font-weight: bold

.text-size-h3
  font-size: 1.4rem
  line-height: 1.8rem
  font-weight: bold

.text-size-h4
  font-size: 1.25rem
  line-height: 1.6rem
  font-weight: bold

.text-large
  font-size: $font-size-base + 0.15rem
  line-height: $line-height-base + 0.15rem

.text-size-default
  font-size: $font-size-base
  line-height: $line-height-base

.text-size-small
  font-size: 0.9rem
  line-height: 1.2rem

h1, h2, h3, h4
  font-family: $font-family-headings
  font-weight: $font-weight-bold
  margin-bottom: math.div($spacer, 2)

.h1
  @extend .text-size-h1

.h2
  @extend .text-size-h2

.h3
  @extend .text-size-h3

.h4
  @extend .text-size-h4

.a
  color: var(--link-color)
  text-decoration: none
  &:visited
    color: var(--link-color)
    text-decoration: none
  &:hover
    color: var(--link-color-hover)
    text-decoration: underline

p
  margin: 0 0 $font-size-base 0
